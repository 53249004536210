import { OrgNameMapping } from '../helpers/enums/organization';

const mattamyHero = '/mattamy/hero.jpeg';
const mattamyLogoDefault = '/mattamy/logoDefault.svg';
const mattamyLogoMobile = '/mattamy/logoDefault.svg';
const mattamyLogoWhite = '/mattamy/logoWhite.svg';

const lennarHero = '/lennar/hero.jpeg';
const lennarLogoDefault = '/lennar/logoDefault.svg';
const lennarLogoMobile = '/lennar/logoMobile.svg';
const lennarLogoWhite = '/lennar/logoWhite.svg';

const tollBrothersHero = '/tollBrothers/hero.jpeg';

const khovHero = '/khov/hero.jpeg';
const khovLogoDefault = '/khov/logoDefault.svg';
const khovLogoMobile = '/khov/logoDefault.svg';
const khovLogoWhite = '/khov/logoWhite.svg';

import hbiaHero from '../assets/hbia/hero.jpeg';
import hbiaLogoDefault from '../assets/hbia/logoDefault.svg';
import hbiaLogoMobile from '../assets/hbia/logoDefault.svg';
import hbiaLogoWhite from '../assets/hbia/logoWhite.svg';

type ImagesSet = {
  hero: string;
  logoDefault: string;
  logoMobile: string;
  logoWhite: string;
};

export const organizationImages: Record<OrgNameMapping, ImagesSet> = {
  [OrgNameMapping.Default]: {
    hero: '',
    logoDefault: '',
    logoMobile: '',
    logoWhite: '',
  },
  [OrgNameMapping.Mattamy]: {
    hero: mattamyHero,
    logoDefault: mattamyLogoDefault,
    logoMobile: mattamyLogoMobile,
    logoWhite: mattamyLogoWhite,
  },
  [OrgNameMapping.Khov]: {
    hero: khovHero,
    logoDefault: khovLogoDefault,
    logoMobile: khovLogoMobile,
    logoWhite: khovLogoWhite,
  },
  [OrgNameMapping.Lennar]: {
    hero: lennarHero,
    logoDefault: lennarLogoDefault,
    logoMobile: lennarLogoMobile,
    logoWhite: lennarLogoWhite,
  },
  [OrgNameMapping.Toll]: {
    hero: tollBrothersHero,
    logoDefault: hbiaLogoDefault,
    logoMobile: hbiaLogoMobile,
    logoWhite: hbiaLogoWhite,
  },
  [OrgNameMapping.Hbia]: {
    hero: hbiaHero,
    logoDefault: hbiaLogoDefault,
    logoMobile: hbiaLogoMobile,
    logoWhite: hbiaLogoWhite,
  },
};
