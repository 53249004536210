import { push } from 'connected-react-router';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { device } from '@hippo/components';
import { PageType } from '../../helpers/enums/pageType';
import { getLandingPath } from '../../helpers/paths';
import { deprecatedLogoId } from './backgroundImageId';
import { PAGES } from '../../globals/routes';

export const Container = styled.div`
  text-align: center;
  padding: 41px 0;
  cursor: pointer;
`;

export interface LogoProps {
  isWhite?: HippoComponents.BreakpointProp<boolean>;
  orientation?: HippoComponents.BreakpointProp<'vertical' | 'horizontal'>;
  logoUrl?: string;
  logos?: {
    logo_default: string;
    logo_mobile: string;
    logo_white: string;
  };
}

interface LogoImages extends LogoProps {
  logos: {
    logo_default: string;
    logo_mobile: string;
    logo_white: string;
  };
}

export const LogoImage = styled.div<LogoImages>`
  width: 300px;
  height: 110px;
  background-size: contain;
  ${device.mobile} {
    width: 350px; //Checked that for different organisations and 350px will look much better than 200px
    background-image: url('${(p) => (p.orientation === 'horizontal' ? p.logos.logo_default : p.logos.logo_mobile)}');
    background-position: center;
  }

  ${device.tablet} {
    background-image: url('${(p) => (p.orientation === 'horizontal' ? p.logos.logo_default : p.logos.logo_mobile)}');
  }

  ${device.desktop} {
    height: 65px;
    background-image: url('${(p) => (p.isWhite ? p.logos.logo_white : p.logos.logo_default)}');
    object-fit: cover;
    background-repeat: no-repeat;
  }
`;

const replacePageKey = (url: string, newPageKey: keyof typeof PAGES) => {
  url = url.replace(PAGES.eligibility, newPageKey);
  url = url.replace(PAGES.checkout, newPageKey);
  url = url.replace(PAGES.success, newPageKey);

  return url;
};

export const generateLandingPageUrl = (pageType?: PageType) => {
  let landingPageUrl;
  const location = window.location;
  const baseUrl = `${location.pathname}${location.search}`;

  switch (pageType) {
    case PageType.Consent:
      landingPageUrl = replacePageKey(baseUrl, PAGES.welcome);
      return landingPageUrl;
    case PageType.Confirm:
      landingPageUrl = replacePageKey(baseUrl, PAGES.coverages);
      return landingPageUrl;
    case PageType.Referral:
      const searchParams = new URLSearchParams(location.search);
      const communityId = searchParams.get('communityId');
      const modelId = searchParams.get('modelId');
      if (communityId && modelId) {
        landingPageUrl = getLandingPath(communityId, modelId);
      }
      return landingPageUrl;
    default:
      return landingPageUrl;
  }
};

export const defaultLogos = {
  logo_default: '',
  logo_white: '',
  logo_mobile: '',
};

/**
 * @deprecated
 */
const DeprecatedLogo: FC<LogoProps> = ({ isWhite, orientation, logoUrl, logos }) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    if (logoUrl) {
      dispatch(push(logoUrl));
    }
  }, [logoUrl, dispatch]);

  return (
    <Container onClick={handleClick}>
      <LogoImage
        data-testid={deprecatedLogoId.backgroundImage}
        isWhite={isWhite}
        orientation={orientation}
        logos={logos || defaultLogos}
      />
    </Container>
  );
};

export default DeprecatedLogo;
